import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { useHistory, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import Modal from 'components/Modals/Modal';
import AiSearch from './AiSearch';

export const ARIA_ID = 'ai-search';

const useStyles = makeStyles(() => ({
  modal: {
    '& [data-reach-dialog-content]': {
      width: '100%',
      maxWidth: 756,
      padding: 0,
    },
  },
}));

function AiSearchModal() {
  const classes = useStyles();
  const isXsDown = useSelector(select.ui.isXsDown);
  const history = useHistory();

  const closeModal = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Modal
      handleClose={closeModal}
      open
      ariaId={ARIA_ID}
      hasDescription
      fullScreen={isXsDown}
      customStyles={classes.modal}
    >
      <AiSearch />
    </Modal>
  );
}

export default withRouter(AiSearchModal);
