import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import MultiAutocomplete from 'components/ModernExperience/Search/Autocomplete/MultiAutocomplete';
import StyleguideCanvas from './StyleguideCanvas';

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function AutocompleteStyleguide() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h2" align="center">
        Autocomplete Components
      </Typography>

      <StyleguideCanvas name="MultiAutocomplete">
        <MultiAutocomplete />
      </StyleguideCanvas>
    </Container>
  );
}
