import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { AiSparkle } from 'icons/colored';

import { Typography, Grid, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ARIA_ID } from './AiSearchModal';
import AiSearchContent from './AiSearchContent';
import AiSearchInput from './AiSearchInput';

const useStyles = makeStyles((theme) => ({
  container: { maxHeight: 'calc(100vh - 64px)', overflow: 'auto' },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
  },
  scrollContainer: { flex: 1, overflow: 'auto' },
  paddedSection: {
    padding: `${theme.spacing(2)}px ${theme.spacing(10)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));

function AiSearch() {
  const classes = useStyles();

  const specialties = useSelector(select.aiSearch.specialtyRecommendations);
  const loading = useSelector(select.aiSearch.loading);
  const error = useSelector(select.aiSearch.error);
  const chatResponse = useSelector(select.aiSearch.chatResponse);

  const showResponse = useMemo(
    () => Boolean(loading || error || chatResponse || specialties.length),
    [chatResponse, error, loading, specialties.length]
  );

  return (
    <Grid container direction="column" wrap="nowrap" classes={{ root: classes.container }}>
      <Box classes={{ root: classes.paddedSection }}>
        <Typography
          color="primary"
          id={`${ARIA_ID}-title`}
          variant="h2"
          classes={{ root: classes.title }}
        >
          <AiSparkle /> AI Search
        </Typography>
        <Typography id={`${ARIA_ID}-description`} paragraph>
          Type a prompt or search term below to get suggested specialties that match your inquiry.
        </Typography>
        <AiSearchInput />
      </Box>
      {showResponse && (
        <>
          <Divider />
          <Box classes={{ root: `${classes.paddedSection} ${classes.scrollContainer}` }}>
            <AiSearchContent />
          </Box>
        </>
      )}
    </Grid>
  );
}

export default AiSearch;
