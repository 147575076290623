import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'propTypes/index';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Divider, Grid } from '@material-ui/core';
import { select } from 'store/toolkit';
import { PLACE_TYPE, PROVIDER_TYPE } from 'utils/constants';
import clsx from 'clsx';
import { debounce } from 'lodash';

import SkipLink from 'components/a11y/SkipLink';
import SearchAppendixContent from './SearchAppendixContent';
import LetterAnchorLinks from './LetterAnchorLinks';
import FilterAppendixInput from './FilterAppendixInput';
import { getMatchingSpecialties } from './utils';

const useStyles = makeStyles((theme) => ({
  container: { height: '100%', overflow: 'scroll' },
  paddedSection: {
    padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  scrollContainer: {
    flex: 2,
    overflow: 'auto',
    minHeight: '25vh', // prevent section from disappearing on zoomed in or landscape views
  },
  bottomSkipLink: {
    top: 'auto',
    bottom: 0,
  },
}));

const DEBOUNCE_DELAY = 300;
const ARIA_ID = 'search-appendix';

export const APPENDIX_STRING_MAP = {
  [PROVIDER_TYPE]: {
    modalTitle: 'All Provider Specialties',
    listLabel: 'specialties',
    subspecialtyLabel: 'focus areas',
  },
  [PLACE_TYPE]: {
    modalTitle: 'All Facility Types',
    listLabel: 'facility types',
    subspecialtyLabel: 'types',
  },
};

export default function SearchAppendix({ resultType }) {
  const classes = useStyles();

  const providerSpecialties = useSelector(select.specialties.providerSpecialtiesArray);
  const placeSpecialties = useSelector(select.specialties.placeSpecialtiesArray);
  const specialties = useMemo(
    () => (resultType === PROVIDER_TYPE ? providerSpecialties : placeSpecialties),
    [placeSpecialties, providerSpecialties, resultType]
  );

  const { modalTitle, listLabel } = useMemo(
    () => APPENDIX_STRING_MAP[resultType] || {},
    [resultType]
  );
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [filterText, setFilterText] = useState('');

  const debounceFilterText = useMemo(
    () =>
      debounce((val) => {
        setFilterText(val);
        setIsDebouncing(false);
      }, DEBOUNCE_DELAY),
    []
  );

  const handleFilterInput = useCallback(
    (val) => {
      setIsDebouncing(true);
      debounceFilterText(val);
    },
    [debounceFilterText]
  );

  const filteredSpecialties = useMemo(
    () => (filterText.length ? getMatchingSpecialties(specialties, filterText) : specialties),
    [specialties, filterText]
  );
  const specialtyCount = useMemo(
    () => filteredSpecialties?.length || 0,
    [filteredSpecialties?.length]
  );

  const filterHelperText = useMemo(() => {
    if (filterText && specialtyCount) return `${specialtyCount} matching ${listLabel}`;
    if (filterText && !specialtyCount) return `no matching ${listLabel}`;
    return null;
  }, [filterText, listLabel, specialtyCount]);

  /* Scrolling and scroll refs */
  const topAnchor = useRef();
  const scrollRefs = useRef({});
  const scrollContainerRef = useRef(null);
  const [currentLetter, setCurrentLetter] = useState('A');

  const scrollToLetter = useCallback(
    (letter) => {
      const container = scrollContainerRef?.current;
      const targetElement = scrollRefs?.current?.[letter];
      setCurrentLetter(letter);

      if (!targetElement || !container) return;

      targetElement.scrollIntoView({
        behavior: 'smooth',
      });
    },
    [scrollContainerRef, scrollRefs]
  );

  const focusOnLetter = useCallback(
    (letter) => {
      const targetElement = scrollRefs?.current?.[letter];
      if (!targetElement) return;
      targetElement.focus();
    },
    [scrollRefs]
  );

  const scrollToTop = useCallback(() => {
    const container = scrollContainerRef?.current;
    if (!container) return;
    container.scroll({ top: 0 });
  }, [scrollContainerRef]);

  useEffect(() => {
    scrollToTop();
    if (filteredSpecialties?.length) {
      const topLetter = filteredSpecialties[0].specialtyName?.slice(0, 1)?.toUpperCase();
      if (topLetter) setCurrentLetter(topLetter);
    }
  }, [scrollToTop, filteredSpecialties]);

  const getClosestElement = useCallback(() => {
    const container = scrollContainerRef?.current;
    const anchorRefs = scrollRefs?.current;

    if (!container || !anchorRefs) return null;

    let closestElement = 'A';

    const scrollableTop = container.getBoundingClientRect().top;

    for (const key of Object.keys(anchorRefs)) {
      if (!anchorRefs[key]) continue;
      const childTop = anchorRefs[key].getBoundingClientRect().top;
      const distance = childTop - scrollableTop - 20;

      // this logic ensures that the element with the smallest negative "distance" calculation is returned
      if (distance > 0) {
        break;
      } else {
        closestElement = key;
      }
    }

    return closestElement;
  }, []);

  const onScroll = useCallback(() => {
    const closestLetter = getClosestElement();
    setCurrentLetter(closestLetter);
  }, [getClosestElement]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainerRef.current?.addEventListener('scroll', debounce(onScroll, 50));
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', debounce(onScroll, 50));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScroll, scrollContainerRef.current]);

  const handleSkipToContent = useCallback(() => {
    const firstAnchor = Object.values(scrollRefs.current)?.filter(Boolean)?.[0];
    if (firstAnchor) firstAnchor.focus();
    else scrollContainerRef.current?.focus();
  }, [scrollRefs, scrollContainerRef]);

  const handleSkipToTop = useCallback(() => {
    topAnchor.current?.focus();
  }, [topAnchor]);
  /* End scrolling and scroll refs */

  return (
    <Grid container direction="column" wrap="nowrap" classes={{ root: classes.container }}>
      <Box classes={{ root: classes.paddedSection }}>
        <Typography color="primary" id={`${ARIA_ID}-title`} variant="h2">
          {modalTitle}
        </Typography>
        <Typography id={`${ARIA_ID}-description`}>
          View all {listLabel} available to search within the Provider Guide application
        </Typography>
        <FilterAppendixInput loading={isDebouncing} handleChange={handleFilterInput} />
        <Typography variant="srOnly" aria-live="polite">
          {filterHelperText}
        </Typography>
        <SkipLink handleClick={handleSkipToContent} label={`Skip to ${listLabel} list`} />
        <LetterAnchorLinks
          focusOnLetter={focusOnLetter}
          scrollToLetter={scrollToLetter}
          specialties={filteredSpecialties}
          currentLetter={currentLetter}
        />
      </Box>
      <Divider />
      <Box
        classes={{ root: clsx(classes.paddedSection, classes.scrollContainer) }}
        ref={scrollContainerRef}
      >
        <div
          tabIndex={-1} // programmatically focusable
          aria-label={`top of ${listLabel} list`}
          ref={topAnchor}
        />
        <SearchAppendixContent
          filter={filterText}
          specialties={filteredSpecialties}
          resultType={resultType}
          scrollRefs={scrollRefs}
        />
        <SkipLink
          handleClick={handleSkipToTop}
          label="Back to top"
          additionalClasses={classes.bottomSkipLink}
        />
      </Box>
    </Grid>
  );
}

SearchAppendix.propTypes = {
  resultType: PropTypes.oneOf([PROVIDER_TYPE, PLACE_TYPE]).isRequired,
};
