import { createSelector } from '@reduxjs/toolkit';
import { BANNER_SLICE_NAME } from 'store/slices/slicesNames';
import { CHAT_BANNER_VARIANT } from './bannerConstants';

/** @returns {boolean} True if the banner should be shown */
export const showBanner = (state) => state[BANNER_SLICE_NAME].showBanner;

export const variant = (state) => state[BANNER_SLICE_NAME].variant;
export const text = (state) => state[BANNER_SLICE_NAME].text;
export const color = (state) => state[BANNER_SLICE_NAME].color;

export const chatBannerIsOpen = createSelector(
  [showBanner, variant],
  (s, v) => s && v === CHAT_BANNER_VARIANT
);
