import { createSlice } from '@reduxjs/toolkit';

import { AI_SEARCH_SLICE_NAME } from '../slicesNames';
import { aiSearch } from './aiSearchThunks';
import { formatAiSearchResponse } from './aiSearchUtils';

const initialState = {
  searchInputValue: '',
  searchPrompt: false,
  loading: false,
  error: null,
  response: {
    chatResponse: '',
    recommendations: {
      specialties: [],
    },
  },
};

const aiSearchSlice = createSlice({
  name: AI_SEARCH_SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setInput: (state, action) => {
      state.searchInputValue = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(aiSearch.pending, (state) => {
      state.searchPrompt = state.searchInputValue;
      state.loading = true;
      state.error = null;
      state.response = initialState.response;
    });
    builder.addCase(aiSearch.fulfilled, (state, action) => {
      const response = formatAiSearchResponse(action.payload);
      state.loading = false;
      state.response = response;
    });
    builder.addCase(aiSearch.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default aiSearchSlice;
export const { reset, setInput } = aiSearchSlice.actions;
