import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';

import { select } from 'store/toolkit';
import AiSearchButton from 'components/ModernExperience/Search/AiSearchButton';

const useStyles = makeStyles((theme) => ({
  mobileSearchButton: {
    flex: 1,
    fontSize: '.8rem',
    display: 'flex',
    justifyContent: 'flex-start',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    '-webkit-box-shadow': '0px 2px 6px rgba(0, 0, 0, 0.2)',
    '-moz-box-shadow': '0px 2px 6px rgba(0, 0, 0, 0.2)',
    '&.MuiButton-outlined': {
      border: '1px solid #484A4C',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
    },
  },
  searchIcon: {
    color: theme.palette.grey[700],
    height: 32,
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  dot: {
    height: '6px',
    width: '6px',
    backgroundColor: theme.palette.common.black,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10,
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  svgIcon: {
    height: 14,
    width: 14,
    marginRight: theme.spacing(0.5),
  },
}));

export default function MobileSearchButton() {
  const classes = useStyles();
  const locationInput = useSelector(select.location.locationInput);
  const searchTypeOptions = useSelector(select.search.currentSearchTypeOptions);
  const hasSearchType = useSelector(select.search.isValidSearchType);
  const enableAiSearch = useSelector(select.featureFlags.enableAiSearch);
  const searchInput = useSelector(select.search.text);
  const history = useHistory();

  const searchTypeIcon = useMemo(() => {
    const iconProps = {
      alt: 'care category icon',
      className: classes.svgIcon,
    };

    if (searchTypeOptions?.IconComponent) {
      return <searchTypeOptions.IconComponent {...iconProps} />;
    }

    // fallback icon
    return <LocalHospitalOutlinedIcon {...iconProps} />;
  }, [searchTypeOptions, classes]);

  return (
    <Grid container style={{ padding: 5 }}>
      <Button
        onClick={() => {
          history.push('/search');
        }}
        variant="outlined"
        className={classes.mobileSearchButton}
      >
        <SearchIcon alt="search" className={classes.searchIcon} />
        <div style={{ textAlign: 'left' }}>
          <Typography component="div" color="secondary" className={classes.locationContainer}>
            <NearMeOutlinedIcon color="secondary" className={classes.svgIcon} /> {locationInput}
          </Typography>
          {hasSearchType ? (
            <div style={{ flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>
                {searchTypeIcon}
                By {searchTypeOptions?.shortName || ''}
              </span>
              <span>
                <span className={classes.dot} />
                {searchInput}
              </span>
            </div>
          ) : (
            <Typography component="span">What are you looking for?</Typography>
          )}
        </div>
      </Button>
      {enableAiSearch && <AiSearchButton iconOnly />}
    </Grid>
  );
}
