import React from 'react';

export default function highlightSearchTerms(searchString, originalString) {
  if (!originalString.length) return '';

  if (!searchString.length) {
    return originalString;
  }

  const index = originalString.toLowerCase().indexOf(searchString.toLowerCase());

  if (index === -1) {
    return originalString;
  }

  const highlightedString = (
    <span>
      {index > 0 && originalString.slice(0, index)}
      <b>{originalString.slice(index, index + searchString.length)}</b>
      {originalString.slice(index + searchString.length)}
    </span>
  );

  return highlightedString;
}
