import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { actions, select, thunks } from 'store/toolkit';

import { Toolbar, Button, Badge, Typography } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';

import useFocusAnchors, { useFocusAnchorKeys, useFocusTo } from 'utils/FocusRefContext';

import Modal from 'components/Modals/Modal';
import useFilterOptionsQuery from 'hooks/filters/useFilterOptionsQuery';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

import ApplyFiltersButton from './ApplyFiltersButton';
import PlaceFilterMenu from './PlaceFilterMenu';
import ProviderFilterMenu from './ProviderFilterMenu';

const useStyles = makeStyles((theme) => ({
  badgeRoot: {
    [theme.breakpoints.down('sm')]: { flex: 1 },
  },
  badgeCount: {
    top: 4,
    right: 6,
  },
  filterButtonRoot: {
    width: '100%',
  },
  filterButtonWithFiltersApplied: {
    borderColor: theme.palette.primary.main,
  },
  chip: {
    marginLeft: 10,
    height: 25,
    lineHeight: 25,
  },
  toolbarHeader: {
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  distanceLabel: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.grey[700],
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 10,
  },
  slider: {
    width: '90%',
  },
  popupButton: {
    border: `2px solid ${theme.palette.grey[400]}`,
    color: theme.palette.common.black,
    fontWeight: '600',
    borderRadius: 25,
    background: 'white',
    fontSize: 13,
    padding: '4px 10px',
    marginRight: '15px',
    '& svg': {
      paddingLeft: 3,
      color: theme.palette.common.black,
    },
    '&.Mui-disabled:disabled': {
      background: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
    overflow: 'visible',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  popupButtonActiveFilter: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  filterLabel: {
    '&.Mui-focused': {
      color: theme.palette.grey[900],
    },
  },
  modalContent: {
    padding: '24px 40px 16px',
    flex: '1 1 auto',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  modalOverrides: {
    '& [data-reach-dialog-content]': {
      padding: 0,
    },
    '& .modal-content-wrapper': {
      overflowY: 'hidden',
    },
  },
  toolbarRoot: {
    flex: '0 1 70px',
    borderTop: 'solid',
    borderTopColor: theme.palette.grey[400],
    justifyContent: ({ wasPlaceSearch }) => (wasPlaceSearch ? 'end' : 'space-between'),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      justifyContent: ({ wasPlaceSearch }) => (wasPlaceSearch ? 'center' : 'space-between'),
      padding: `0px ${theme.spacing(2)}px`,
    },
  },
}));

function FilterMenu() {
  const focusAnchorKeys = useFocusAnchorKeys();
  const [chipOpen, setChipOpen] = useState(false);

  const defaultSearchRadius = useSelector(select.config.defaultSearchRadius);
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();

  const filterMenuOpen = useSelector(select.ui.filterMenuOpen);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const totalAppliedFilters = useSelector(select.results.totalAppliedFilters); // The total number of filters applied to the result state
  const resultFilters = useSelector(select.results.filters);
  const resultLocation = useSelector(select.results.location);
  const optionsInvalidated = useSelector(select.filters.optionsInvalidated);
  const resultsSearchRadius = useSelector(select.results.searchRadius);
  const resultCount = useSelector(select.results.count);
  const isLoading = useSelector(select.results.isLoading);

  const classes = useStyles({ wasPlaceSearch });

  const focusAnchors = useFocusAnchors();
  const focusTo = useFocusTo();
  const smDown = useSelector(select.ui.isSmDown);

  useEffect(() => {
    // reset filter & location state to match that of result set
    if (filterMenuOpen) {
      dispatch(
        actions.filters.updateFromResults({ filters: resultFilters, radius: resultsSearchRadius })
      );
      setChipOpen(!resultLocation.radius);
      dispatch(actions.filters.invalidateOptions());
    }
    // only execute when `modalOpen` changed, and is true
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filterMenuOpen]);

  const handleManualClose = useCallback(() => {
    // when modal is closed without applying new filters,
    // reset filter & location state to match that of result set
    dispatch(
      actions.filters.updateFromResults({ filters: resultFilters, radius: resultsSearchRadius })
    );
    dispatch(actions.ui.closeModal('filter'));
  }, [resultFilters, dispatch, resultsSearchRadius]);

  const queryString = useFilterOptionsQuery();
  useEffect(() => {
    if (filterMenuOpen && optionsInvalidated) {
      dispatch(thunks.filters.fetchFilterOptions(queryString));
    }
  }, [dispatch, optionsInvalidated, filterMenuOpen, queryString]);

  const toggleModalOpen = useCallback(() => dispatch(actions.ui.openModal('filter')), [dispatch]);

  const handleExit = useCallback(() => {
    focusTo.filterButton();
  }, [focusTo]);

  const handleCloseChip = () => {
    setChipOpen(false);
    dispatch(actions.filters.setFilterRadius(defaultSearchRadius));
  };

  const handleClearFilters = () => {
    dispatch(actions.filters.clear());
  };

  const handleApplyFilters = () => {
    searchDispatch(thunks.results.applyFilterSearch({ isBoundsSearch: chipOpen }));
    dispatch(actions.ui.setReturnFocusToKey(focusAnchorKeys.filterButton));
    dispatch(actions.ui.closeModal('filter'));
  };

  const disabled = useMemo(
    () => (resultCount <= 1 && totalAppliedFilters === 0) || isLoading,
    [resultCount, totalAppliedFilters, isLoading]
  );

  return (
    <>
      <Badge
        badgeContent={totalAppliedFilters}
        color="primary"
        classes={{ root: classes.badgeRoot, badge: classes.badgeCount }}
      >
        <Button
          onClick={toggleModalOpen}
          aria-label={`Filter results, open submenu. ${
            totalAppliedFilters > 0 ? `${totalAppliedFilters} filters currently selected` : ''
          }`}
          id="filter-button"
          disabled={disabled}
          ref={focusAnchors.filterButton}
          startIcon={<TuneIcon />}
          variant="outlined"
          classes={{
            root: classes.filterButtonRoot,
            outlined: totalAppliedFilters && classes.filterButtonWithFiltersApplied, // should give it a colored outlined if filters are applied
          }}
        >
          Filters
        </Button>
      </Badge>

      <Modal
        open={filterMenuOpen}
        handleClose={handleManualClose}
        exitFunction={handleExit}
        fullWidth
        fullScreen={smDown}
        ariaId="filter-modal"
        customStyles={classes.modalOverrides}
      >
        <div className={classes.modalContent}>
          <Typography variant="h2" id="filter-modal-title" className={classes.toolbarHeader}>
            Filters
          </Typography>

          {wasPlaceSearch ? (
            <PlaceFilterMenu
              handleCloseChip={handleCloseChip}
              chipOpen={chipOpen}
              classes={classes}
            />
          ) : (
            <ProviderFilterMenu
              handleCloseChip={handleCloseChip}
              chipOpen={chipOpen}
              classes={classes}
            />
          )}
        </div>
        <Toolbar
          classes={{
            root: classes.toolbarRoot,
          }}
        >
          {!wasPlaceSearch && (
            <Button
              onClick={handleClearFilters}
              role="button"
              aria-label="clear all selected filters"
            >
              Clear All
            </Button>
          )}
          <ApplyFiltersButton onClick={handleApplyFilters} />
        </Toolbar>
      </Modal>
    </>
  );
}

export default FilterMenu;
