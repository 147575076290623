import { createAsyncThunk } from '@reduxjs/toolkit';
import { logDevMessage } from 'utils/utils';

import { SEARCH_SLICE_NAME } from '../slicesNames';
import { search as selectResultsSearch } from '../results/selectResults';
import * as selectAxios from '../config/selectAxios';
import { autocompleteBaseUrl as selectAutocompleteBaseUrl } from '../../fusionServices/selectFusion';
import { overwriteSlice } from './searchSlice';
import { CARE_CATEGORY_AUTOCOMPLETE_TYPE_PARAM_MAP } from './searchConstants';

export const updateSearchFromResults = createAsyncThunk(
  `${SEARCH_SLICE_NAME}/updateFromResults`,
  async (args, thunkApi) => {
    const { getState, dispatch } = thunkApi;
    const state = getState();

    const resultsSearchValues = selectResultsSearch(state);

    dispatch(overwriteSlice(resultsSearchValues));
  }
);

export const getTypeSuggestions = createAsyncThunk(
  `${SEARCH_SLICE_NAME}/getTypeSuggestions`,
  async (careCategory, thunkApi) => {
    const type = CARE_CATEGORY_AUTOCOMPLETE_TYPE_PARAM_MAP[careCategory];
    if (!type) {
      logDevMessage(`Invalid inputs: { 'careCategory': ${careCategory}, 'type': ${type} }`);
      return { type, careCategory, data: [] };
    }

    const { getState } = thunkApi;
    const state = getState();
    const axios = selectAxios.axiosInstance(state);
    const baseUrl = selectAutocompleteBaseUrl(state);

    if (!type || !baseUrl) {
      return { type, careCategory, data: [] };
    }

    const url = `${baseUrl}&type=${type}`;
    const res = await axios.get(url);

    return { type, careCategory, data: res.data };
  }
);
