import React, { useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { select } from 'store/toolkit';

import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import LanguageIcon from '@material-ui/icons/Language';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import Layers from '@material-ui/icons/Layers';

import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import OutcareColored from 'icons/colored/OutcareColored';
import StarBoxed from 'icons/colored/StarBoxed';
import Female from 'icons/Female';
import Male from 'icons/Male';

import Attribute from '../../Attribute';

export function getProviderAttributeList({
  showAcceptingNewPatients,
  showNearbyLocations,
  showTierDesignation,
  tierDesignation,
  networkData = {},
  providerData = {},
  numberOfLocationsAcceptingNewPatients = 0,
  hasTelehealthAvailable = false,
  showBenefitDecrease = false,
  benefitsChangeData = {},
  isPreferredGroup,
  palette,
}) {
  const list = [];

  // 1. network designation (optional)
  if (networkData && networkData.showNetworkStatus) {
    list.push({
      key: 'attribute-network',
      icon: (
        <LanguageIcon
          color="inherit"
          fontSize="inherit"
          htmlColor={get(palette, networkData.textColor)}
        />
      ),
      text: networkData.networkText,
      TooltipProps: { message: networkData.tooltipMessage },
      TypographyProps: {
        style: {
          fontWeight: 'bold',
          color: get(palette, networkData.textColor),
        },
      },
    });
  }

  // 2. tier designation
  if (tierDesignation && showTierDesignation) {
    list.push({
      key: 'attribute-tier-designation',
      text: tierDesignation.text,
      TooltipProps: { message: tierDesignation.tooltipMessage },
      icon: <Layers />,
    });
  }

  if (isPreferredGroup) {
    list.push({
      key: 'preferred-group',
      text: 'Preferred Group',
      icon: <StarBoxed />,
    });
  }

  // 3. benefit decrease
  if (showBenefitDecrease) {
    const { cardAttribute } = benefitsChangeData;
    list.push({
      key: 'attribute-benefits',
      text: cardAttribute.text,
      TooltipProps: { message: cardAttribute.tooltip },
      iconAltText: `${cardAttribute.text} icon`,
      icon: <ErrorIcon htmlColor={palette.warning.main} />,
    });
  }

  // 4. gender
  list.push({
    key: 'attribute-gender',
    text: providerData.gender,
    icon:
      providerData.gender === 'Female' ? (
        <Female htmlColor={palette.text.primary} />
      ) : (
        <Male htmlColor={palette.text.primary} />
      ),
    iconAltText: `${providerData.gender} Provider icon`,
  });

  // 5. Languages Spoken
  if (providerData.languagesArray?.length > 0) {
    list.push({
      key: 'attribute-language',
      text: providerData.languagesArray.join(', '),
      icon: <RecordVoiceOverOutlinedIcon htmlColor={palette.text.primary} />,
      iconAltText: 'Languages Spoken Icon',
    });
  }

  // 6. LGBTQ+ (optional)
  if (providerData.outcareCompetent) {
    list.push({
      key: 'attribute-outcare',
      text: 'LGBTQ+ Competent',
      icon: <OutcareColored />,
      iconAltText: 'LGBTQ+ Competent Icon',
    });
  }

  // 7. telehealth
  if (hasTelehealthAvailable) {
    list.push({
      key: 'attribute-telehealth',
      text: 'Telehealth',
      icon: <CheckOrXIcon checked />,
    });
  }

  // 8. accepting new
  if (showAcceptingNewPatients) {
    list.push(
      numberOfLocationsAcceptingNewPatients > 0
        ? {
            key: 'attribute-accepting-patients',
            text: 'Accepting patients',
            icon: <CheckOrXIcon checked />,
          }
        : {
            key: 'attribute-accepting-patients',
            text: 'Not accepting patients',
            icon: <CheckOrXIcon checked={false} />,
          }
    );
  }

  // 9. nearby locations
  if (showNearbyLocations) {
    if (providerData.places?.length > 1) {
      list.push({
        key: 'attribute-locations',
        text: `${providerData.places.length} nearby locations`,
        icon: <RoomOutlinedIcon htmlColor={palette.text.primary} />,
      });
    }
  }

  return list;
}

function ProviderAttributeList({
  showAcceptingNewPatients,
  showNearbyLocations,
  showTierDesignation,
  providerId,
  ...props
}) {
  const { palette } = useTheme();
  const providerData = useSelector(select.provider(providerId).data);
  const showBenefitDecrease = useSelector(select.provider(providerId).showBenefitDecrease);
  const isPreferredGroup = useSelector(select.provider(providerId).isPreferredGroup);
  const benefitsChange = useSelector(select.content.benefitsChange);
  const numberOfLocationsAcceptingNewPatients = useSelector(
    select.provider(providerId).numberOfLocationsAcceptingNewPatients
  );
  const hasTelehealthAvailable = useSelector(select.provider(providerId).hasTelehealthAvailable);
  const tierDesignation = useSelector(select.provider(providerId).tierDesignationRollup);
  const network = useSelector(select.provider(providerId).networkContent);

  const attributeList = useMemo(
    () =>
      getProviderAttributeList({
        showAcceptingNewPatients,
        showNearbyLocations,
        showTierDesignation,
        networkData: network,
        providerData,
        numberOfLocationsAcceptingNewPatients,
        hasTelehealthAvailable,
        tierDesignation,
        showBenefitDecrease,
        benefitsChangeData: benefitsChange,
        isPreferredGroup,
        palette,
      }),
    [
      network,
      providerData,
      numberOfLocationsAcceptingNewPatients,
      hasTelehealthAvailable,
      tierDesignation,
      showBenefitDecrease,
      benefitsChange,
      showAcceptingNewPatients,
      showNearbyLocations,
      showTierDesignation,
      isPreferredGroup,
      palette,
    ]
  );

  if (!attributeList.length) return null;

  return (
    <List disablePadding {...props}>
      {attributeList.map(({ key, ...attribute }) => (
        <Attribute key={`${key}-${providerId}`} {...attribute} />
      ))}
    </List>
  );
}

ProviderAttributeList.propTypes = {
  providerId: PropTypes.string.isRequired,
  showAcceptingNewPatients: PropTypes.bool,
  showNearbyLocations: PropTypes.bool,
  showTierDesignation: PropTypes.bool,
};

ProviderAttributeList.defaultProps = {
  showAcceptingNewPatients: true,
  showNearbyLocations: true,
  showTierDesignation: true,
};

export default React.memo(ProviderAttributeList);
