import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { PROVIDER_RESULT_TYPE } from 'utils/constants';
import { select } from 'store/toolkit';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import EmboldAnalysisStepper from '../ChatModal/ChatLoading/EmboldAnalysisStepper';
import SearchAppendixItems from '../SearchAppendixModals/SearchAppendixItems';

const AI_SEARCH_LOADING_MESSAGES = [
  'Processing your message',
  'Finding matching provider specialties',
  'Delivering your results',
];

export default function AiSearchContent() {
  const specialties = useSelector(select.aiSearch.specialtyRecommendations);
  const loading = useSelector(select.aiSearch.loading);
  const error = useSelector(select.aiSearch.error);
  const chatResponse = useSelector(select.aiSearch.chatResponse);
  const searchPrompt = useSelector(select.aiSearch.searchPrompt);

  const [showResults, setShowResults] = useState(chatResponse);

  useEffect(() => {
    if (!chatResponse) setShowResults(false);
  }, [chatResponse]);

  if (error) {
    return (
      <Grid container alignItems="center" style={{ columnGap: 8 }}>
        <ErrorIcon color="error" />
        <Typography>There was an error. Please try again.</Typography>
      </Grid>
    );
  }

  if (showResults) {
    return (
      <>
        <Typography variant="h3" color="primary">
          AI Generated results for &ldquo;{searchPrompt}&rdquo;
        </Typography>
        <Typography style={{ fontSize: '.925rem' }} paragraph>
          {chatResponse}
        </Typography>
        <SearchAppendixItems
          resultType={PROVIDER_RESULT_TYPE}
          specialties={specialties}
          sortItems={false}
        />
      </>
    );
  }

  return (
    <EmboldAnalysisStepper
      messages={AI_SEARCH_LOADING_MESSAGES}
      onComplete={() => setShowResults(true)}
      onCompleteDelay={300}
      loading={loading}
    />
  );
}
