import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, List, Select, MenuItem, Grid, Link } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import AcUnitIcon from '@material-ui/icons/AcUnit';

import FocusAreasColored from 'icons/colored/FocusAreasColored';
import allergyTestIcon from 'assets/allergytest.svg';

import providers from 'sampleData/providers';
import useUpdateTitle from 'hooks/useUpdateTitle';
import Metric from 'components/ModernExperience/Metric';
import ProviderMetricList from 'components/ModernExperience/ResultCards/ProviderCard/ProviderMetricList';

import ShowMoreButton from 'components/ShowMoreButton';
import useToggleState from 'hooks/useToggleState';
import Attribute from 'components/ModernExperience/Attribute';
import ContactCard from 'components/ModernExperience/ResultCards/ResultLocations/ContactCard';
import PaginationController from 'components/PaginationController';
import ResultCard from 'components/ModernExperience/ResultCards/ResultCard';
import {
  DESKTOP_SMALL_VIEW,
  MOBILE_CONDENSED_VIEW,
  PROVIDER_CARD_VIEW_OPTIONS,
} from 'store/slices/ui/uiConstants';
import { MatchedField } from 'components/ModernExperience/ResultCards/MatchedOnSection';
import ResultCardSkeleton from 'components/ModernExperience/LoadingSkeletons/ResultCardSkeleton';
import HospitalGradeIcon from 'icons/dynamic/HospitalGradeIcon';

import StyleguideCanvas from './StyleguideCanvas';
import withDataToggle from './withDataToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    marginTop: 16,
  },
}));

function ResultCardsStyleguide() {
  const classes = useStyles();
  const resultIds = useSelector(select.results.idList);
  const isLoading = useSelector(select.results.isLoading);
  const wasProviderSearch = useSelector(select.results.wasProviderSearch);
  const samplePlaces = providers[0].places;

  useUpdateTitle('Modern Experience Styleguide - Result Cards', { hidePrefix: true });

  const [showMore, toggleShowMore] = useToggleState();
  const [page, setPage] = useState(0);
  const [view, setView] = useState(PROVIDER_CARD_VIEW_OPTIONS[0]);

  if (!resultIds || resultIds.length === 0 || isLoading) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h1">Modern Experience Styleguide - Result Cards</Typography>
      <StyleguideCanvas
        name={`${wasProviderSearch ? 'Provider' : 'Place'} result card`}
        canvasStyle={{
          // eslint-disable-next-line no-nested-ternary
          maxWidth: view.includes('MOBILE') ? 320 : view === DESKTOP_SMALL_VIEW ? 1000 : 2000,
        }}
      >
        <Select value={view} variant="outlined" onChange={(e) => setView(e.target.value)}>
          {PROVIDER_CARD_VIEW_OPTIONS.map((opt) => (
            <MenuItem value={opt} key={opt}>
              {opt}
            </MenuItem>
          ))}
        </Select>
        <br />
        <br />
        <ResultCard resultId={resultIds[0]} view={view} />
        <br />
        <br />
        <ResultCardSkeleton view={view} />
      </StyleguideCanvas>
      <StyleguideCanvas
        name={`${wasProviderSearch ? 'Provider' : 'Place'} result card (with toolbar component)`}
      >
        <Grid container spacing={3}>
          <Grid item style={{ maxWidth: 375 }}>
            <ResultCard resultId={resultIds[0]} view={MOBILE_CONDENSED_VIEW} />
          </Grid>
          <Grid item style={{ maxWidth: 375 }}>
            <ResultCard resultId={resultIds[0]} view={MOBILE_CONDENSED_VIEW} />
          </Grid>
        </Grid>
      </StyleguideCanvas>
      {wasProviderSearch && (
        <>
          <StyleguideCanvas
            name="Metric"
            description={`
        The <Metric /> component is used to display a given measure that is stored on a result. In the case of our provider cards, one example of this metric is Focus Areas. This component is completely stateless and simply displays props with the desired UI.
        Required props are: title, icon and children.
        Optional props are: TooltipProps (object), headingLevel (defaults to h4), and listItem (defaults to false)
        `}
            canvasStyle={{ width: '100%' }} // change the container width here, Metric will occupy 100% of container
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Metric
                  icon={<FocusAreasColored />}
                  title="Metric Title"
                  TooltipProps={{
                    message:
                      'You can insert tooltip text by passing a string to the TooltipProps.message prop',
                    link: { to: '#', label: 'Optional to include a link' },
                  }}
                  headingLevel="h3"
                >
                  Pass a string as a child of metric to render it in this section. The icon is set
                  by the icon prop. There are also props for listItem, which sets the parent element
                  to be an li. And marginBottom for adding margin to the bottom of the container.
                </Metric>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Metric
                  icon={<FocusAreasColored />}
                  title="Metric Title"
                  action={<Link href="/#">Click me</Link>}
                >
                  Pass a node to give the metric component an action in the top right corner
                </Metric>
              </Grid>
            </Grid>
          </StyleguideCanvas>
          <StyleguideCanvas
            name="ProviderMetricList"
            showContainerOptions
            display="col"
            description={`
        The purpose of the <ProviderMetricList /> component is to:
        1. Accept a "result" object in props.
        2. Execute the correct selector to gather the necessary metric data.
        3. Render a list of <Metric /> components with data returned from the selector.
        `}
          >
            <ProviderMetricList providerId={resultIds[0]} MetricProps={{ headingLevel: 'h3' }} />
          </StyleguideCanvas>
        </>
      )}
      <StyleguideCanvas
        name="Attribute"
        description={`
        An Attribute is a simple component used to display some attribute about a promotion, place or provider along with an icon.
        This should typically be rendered in a list but does not have to. You can set isListItem={false} to avoid using an li as the container.
        There is a default checkmark icon but that can be overridden with the icon prop.
        `}
      >
        <List>
          <Attribute text="This is an attribute" />
          <Attribute
            text="Passing a color prop to Typography"
            TypographyProps={{ color: 'error' }}
          />
          <Attribute
            text="Passing a class or style prop to Typography"
            TypographyProps={{ style: { fontWeight: 'bold' } }}
          />
          <Attribute text="With a tooltip" TooltipProps={{ message: 'I have tooltip text!' }} />
          <Attribute
            text="With a tooltip and custom tooltip title"
            TooltipProps={{ message: 'I have tooltip text!', title: 'Custom Title' }}
          />
          <Attribute
            text="With a tooltip and custom tooltip link"
            TooltipProps={{
              message: 'I have a tooltip with a link!',
              link: { to: '/terms', label: 'go to terms page' },
            }}
          />
          <Attribute
            text="With a tooltip and custom tooltip href"
            TooltipProps={{
              message: 'I have a tooltip with a link!',
              href: { to: 'https://emboldhealth.com', label: 'Embold Health' },
            }}
          />
          <Attribute icon={allergyTestIcon} text="Attribute with a custom img icon" />
          <Attribute
            icon={<HospitalGradeIcon grade="A" />}
            iconAltText="C"
            text="This has a custom icon with alt text"
          />
          <Attribute
            icon={<AcUnitIcon color="primary" />}
            text="Attribute with a custom node type icon"
          />
        </List>
        <br />
        <Attribute
          isListItem={false}
          text="This attribute is not part of a list and is made a div with isListItem=false"
        />
      </StyleguideCanvas>

      <StyleguideCanvas
        name="MatchedField"
        description="This is a sub-component of the MatchedOnSection. This is a 'dumb' component and uses no hooks or props. It simply returns a <span> that contains a bolded key, a non-bolded value, and it can optionally wrap with a tooltip."
      >
        <MatchedField label="Foo" />
        <MatchedField label="Foo" value="Bar" />
        <MatchedField
          label="Foo"
          value="Baz"
          tooltip={
            <>
              <strong>Foo</strong>
              <sup>Bar</sup>
              <sub>Baz</sub>
            </>
          }
        />
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Show More Button"
        display="row"
        justify="evenly"
        showContainerOptions
        description={`
        This is intended to be a reusable component for controlling <Collapse /> components. 
        It provides some default text along with animation on the Chevron icon.
        `}
      >
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          ariaControls="test-id-1"
          ariaLabel="test 1"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          variant="contained"
          color="primary"
          ariaControls="test-id-2"
          ariaLabel="test 2"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          variant="outlined"
          ariaControls="test-id-3"
          ariaLabel="test 3"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          showMoreText="Custom Show Me More Text"
          showLessText="Custom Show Me Less Text"
          ariaControls="test-id-4"
          ariaLabel="test 4"
        />
      </StyleguideCanvas>
      <StyleguideCanvas
        name="Pagination Controller"
        description={`
      The Pagination controller is a simple set of controls for moving and index up or down with a prop for the upper limit (length).
      `}
      >
        <PaginationController index={page} length={5} setIndex={setPage} label="Thing" />
      </StyleguideCanvas>
      <StyleguideCanvas
        name="Contact Card"
        display="col"
        align="center"
        description={`
      The ContactCard is a generic card that does not care about the type (provider, place or benefit). It simply accepts an array of locations.
      There are optional props for showMap, showDisclaimer, onPhoneClick/onAddressClick (used for things like analytics).
      `}
      >
        <ContactCard locations={samplePlaces} onAddressClick={() => {}} onPhoneClick={() => {}} />
        <br className={classes.divider} />
        <ContactCard
          locations={samplePlaces}
          onAddressClick={() => {}}
          onPhoneClick={() => {}}
          showDisclaimer
        />
        <br className={classes.divider} />
        <ContactCard
          locations={samplePlaces}
          onAddressClick={() => {}}
          onPhoneClick={() => {}}
          showMap
          showDisclaimer
        />
        <br className={classes.divider} />
        <ContactCard
          locations={samplePlaces}
          onAddressClick={() => {}}
          onPhoneClick={() => {}}
          showMap
          showDisclaimer
        >
          <Typography variant="h2" component="strong">
            Adding children
          </Typography>
          <Typography paragraph>
            If children are added to the contact card, they will be rendered in this section.
          </Typography>
        </ContactCard>
      </StyleguideCanvas>
    </div>
  );
}

export default withDataToggle(ResultCardsStyleguide);
