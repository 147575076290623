import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.57059 11.4754L8.42746 5.85094H9.56694L11.4238 11.4754L16.9944 13.3502V14.5007L11.4238 16.3755L9.56694 22H8.42746L6.57059 16.3755L1 14.5007V13.3502L6.57059 11.4754Z"
      fill="url(#paint0_linear_1061_1271)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0804 3.8958L18.0364 1H19.1759L20.1319 3.8958L23 4.86107V6.01157L20.1319 6.97684L19.1759 9.87265H18.0364L17.0804 6.97684L14.2123 6.01157V4.86107L17.0804 3.8958Z"
      fill="url(#paint1_linear_1061_1271)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1061_1271"
        x1="-2.27481"
        y1="38.275"
        x2="27.9384"
        y2="33.6204"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0181662" stopColor="#00205B" />
        <stop offset="0.597456" stopColor="#1F78D1" />
        <stop offset="1" stopColor="#55A9FE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1061_1271"
        x1="-2.27481"
        y1="38.275"
        x2="27.9384"
        y2="33.6204"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0181662" stopColor="#00205B" />
        <stop offset="0.597456" stopColor="#1F78D1" />
        <stop offset="1" stopColor="#55A9FE" />
      </linearGradient>
    </defs>
  </>,
  'AiSparkle'
);
