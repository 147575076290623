import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Grid, makeStyles, Typography, Link } from '@material-ui/core';
import { actions, select } from 'store/toolkit';
import { getLocalStorageExpire, setLocalStorageExpire } from 'utils/utils';

import Modal from 'components/Modals/Modal';

import useCheckStorageAndShowModal from 'hooks/useCheckStorageAndShowModal';

const PODKey = 'SOCTProvidersOfDistinction';
const PODVersion = 1;

const useStyles = makeStyles(() => ({
  headerText: {
    margin: '20px 0',
  },
  acceptButton: {
    margin: '20px 0',
  },
}));

export const incrementStorageValue = () => {
  const modalShownCount = parseInt(getLocalStorageExpire(PODKey) ?? 0, 10);
  setLocalStorageExpire(PODKey, modalShownCount + 1, 3, PODVersion);
};

export default function SOCTProvidersOfDistinction() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkStorageAndShowModal = useCheckStorageAndShowModal(
    PODKey,
    PODVersion,
    'providersOfDistinction',
    1
  );

  const open = useSelector(select.ui.providersOfDistinctionOpen);

  useEffect(() => {
    checkStorageAndShowModal();
  }, [dispatch, checkStorageAndShowModal]);

  const handleClose = useCallback(() => {
    dispatch(actions.ui.closeModal('providersOfDistinction'));
    incrementStorageValue();
  }, [dispatch]);

  return (
    <Modal
      open={open}
      ariaId="soct-benefits-modal"
      allowBackdropClick={false}
      showX
      handleClose={handleClose}
    >
      <Container component="section">
        <Typography variant="h2" id="soct-benefits-modal-title" className={classes.headerText}>
          Providers of Distinction Update
        </Typography>

        <div>
          <Typography paragraph>
            Effective November 15, 2024, some providers will drop from the Providers of Distinction
            program. To earn an incentive for an eligible procedure after November 14, 2024, your
            provider must be flagged as a Provider of Distinction in the Find Provider tool.
          </Typography>
          <Grid container direction="row" justifyContent="center">
            <Typography paragraph>
              <strong>
                For more information click{' '}
                <Link
                  underline="hover"
                  href="https://carecompass.ct.gov/providersofdistinction/"
                  target="_blank"
                  rel="noreferrer"
                >
                  HERE.
                </Link>
              </strong>
            </Typography>
          </Grid>
        </div>

        <Grid container direction="row" justifyContent="center">
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.acceptButton}
          >
            OK
          </Button>
        </Grid>
      </Container>
    </Modal>
  );
}
