import { createSelector } from '@reduxjs/toolkit';
import selectProvider from 'store/slices/results/selectProvider';
import selectPlace from 'store/slices/results/selectPlace';
import { isPlaceSearch } from '../store/slices/search/selectSearch';
import { getProviderAttributes } from './analyticsUtils';

export function selectProviderAnalyticsAttributes(id) {
  return createSelector(
    [selectProvider(id).data, selectProvider(id).closestLocation, selectProvider(id).pillList],
    (providerData, closestLocation, pillList) => {
      if (!providerData) return {};
      const { distance } = closestLocation || {};
      const providerAttributes = getProviderAttributes(providerData);

      if (pillList?.length) {
        providerAttributes.pills = encodeURIComponent(
          pillList?.map((pill) => pill.label).join(',')
        );
      }
      return {
        ...providerAttributes,
        distance_to_result: distance,
      };
    }
  );
}

function selectPlaceAnalyticsAttributes(id) {
  return createSelector(
    [selectPlace(id).data, selectPlace(id).distanceInMiles],
    (placeData, distance) => {
      if (!placeData) return {};

      return {
        place_id: placeData.id,
        entityName: placeData.name,
        distance_to_result: distance,
        address: placeData.address1,
        phone: placeData.phone,
        zipcode: placeData.zipcode,
      };
    }
  );
}

export function selectClosestProviderLocationData(id) {
  return createSelector([selectProvider(id).closestLocation], (closestLocation) => {
    const { address1, phone, telehealthAvailable, zipcode, acceptingNewPatients } =
      closestLocation || {};
    return {
      address: address1,
      phone: phone,
      telehealth_available: telehealthAvailable,
      zipcode: zipcode,
      accepting_new_patients: acceptingNewPatients,
    };
  });
}

export default function selectResultAnalyticsAttributes(id) {
  if (!id) return {};
  return createSelector(
    [isPlaceSearch, selectProviderAnalyticsAttributes(id), selectPlaceAnalyticsAttributes(id)],
    (isPlaceResult, providerAttributes, placeAttributes) =>
      isPlaceResult ? placeAttributes : providerAttributes
  );
}
