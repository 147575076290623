import {
  CARE_CATEGORY_OPTIONS,
  PLACE_CARE_CATEGORIES,
  PROVIDER_CARE_CATEGORIES,
} from 'utils/constants';

function createExampleOptions(careCategories) {
  const options = [];
  for (const group of Object.values(careCategories)) {
    const commonSearches = CARE_CATEGORY_OPTIONS[group].commonSearches.slice(0, 3).map((cs) => ({
      ...cs,
      group,
    }));
    options.push(...commonSearches);
  }
  return options;
}

export const defaultProviderOptions = createExampleOptions(PROVIDER_CARE_CATEGORIES);
export const defaultPlaceOptions = createExampleOptions(PLACE_CARE_CATEGORIES);
