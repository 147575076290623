import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'propTypes/index';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { Grid, Typography, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingDots from 'components/LoadingDots';

import {
  EMBOLD_ANALYSIS_MESSAGES,
  EMBOLD_ANALYSIS_MESSAGE_INTERVAL,
} from 'store/slices/chat/chatConstants';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import { logDevMessage } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  collapseRoot: {
    maxHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    rowGap: 6,
  },
  icon: {
    width: 24,
    marginRight: 2,
  },
  text: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.grey[700],
  },
}));

export default function EmboldAnalysisStepper({
  messages,
  loading,
  scrollToBottom,
  onComplete,
  onCompleteDelay,
}) {
  const classes = useStyles();
  const [showStepper, setShowStepper] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [currentStep, setCurrentStep] = useState(-1);

  if (messages.length !== 3)
    logDevMessage('Invalid `messages` prop passed to EmboldAnalysisStepper');

  // @TODO: get translated messages
  const steps = useMemo(() => messages.map((text) => ({ text, key: uniqueId() })), [messages]);

  const handleComplete = useCallback(() => {
    setCurrentStep(3);
    clearInterval(intervalId);
    setTimeout(() => {
      setShowStepper(false);
      onComplete();
    }, onCompleteDelay);
  }, [intervalId, onComplete, onCompleteDelay]);

  useEffect(() => {
    // stop incrementing once you reach final step and wait until endpoint sends response
    if (currentStep === 2) clearInterval(intervalId);
  }, [currentStep, intervalId]);

  const beginStepper = useCallback(() => {
    setShowStepper(true);
    setCurrentStep(0);
    const stepIntervalId = setInterval(
      () => setCurrentStep((i) => i + 1),
      EMBOLD_ANALYSIS_MESSAGE_INTERVAL
    );
    setIntervalId(stepIntervalId);
    setTimeout(scrollToBottom, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loading state triggers display and timer
  useEffect(() => {
    if (loading) {
      beginStepper();
    } else if (showStepper) {
      // analyze endpoint has finished
      handleComplete();
    }

    return () => {
      // clean up setInterval timers
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Grid
      aria-hidden={!showStepper}
      classes={{ root: clsx(classes.root, { [classes.collapseRoot]: !showStepper }) }}
      data-testid="embold-analysis-stepper-root"
    >
      <Grid classes={{ root: classes.container }} container direction="column">
        {steps.map(({ text, key }, i) => (
          <Grow in={currentStep >= i} key={key}>
            <Grid container alignItems="center">
              {i === currentStep ? (
                <LoadingDots small additionalClass={classes.icon} />
              ) : (
                <CheckOrXIcon checked className={classes.icon} />
              )}
              <Typography classes={{ root: classes.text }}>{text}</Typography>
            </Grid>
          </Grow>
        ))}
      </Grid>
    </Grid>
  );
}

EmboldAnalysisStepper.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool.isRequired,
  onCompleteDelay: PropTypes.number,
  scrollToBottom: PropTypes.func,
  onComplete: PropTypes.func,
};

EmboldAnalysisStepper.defaultProps = {
  messages: EMBOLD_ANALYSIS_MESSAGES,
  onCompleteDelay: 0,
  scrollToBottom: () => {},
  onComplete: () => {},
};
