import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { select } from 'store/toolkit';

import { APPENDIX_STRING_MAP } from './SearchAppendix';
import SearchAppendixItems, {
  SearchAppendixItemsDefaultProps,
  SearchAppendixItemsProps,
} from './SearchAppendixItems';

export default function SearchAppendixContent(props) {
  const { resultType, specialties, filter } = props;
  const loading = useSelector(select.specialties.loadingSpecialties);
  const error = useSelector(select.specialties.failedToLoadSpecialties);

  const { listLabel } = useMemo(() => APPENDIX_STRING_MAP[resultType] || {}, [resultType]);

  if (error) {
    return <Typography color="error">Error loading {listLabel}</Typography>;
  }

  if (filter?.length && !specialties?.length) {
    return (
      <div>
        No results found for your filter:{' '}
        <em>
          <b>{filter}</b>
        </em>
      </div>
    );
  }

  if (loading || !specialties?.length) {
    return (
      <>
        <Skeleton width="100%" height={50} aria-label={`${listLabel} are loading`} />
        <Skeleton width="100%" height={50} aria-hidden="true" />
        <Skeleton width="100%" height={50} aria-hidden="true" />
        <Skeleton width="100%" height={50} aria-hidden="true" />
        <Skeleton width="100%" height={50} aria-hidden="true" />
      </>
    );
  }

  return <SearchAppendixItems sortItems {...props} />;
}

SearchAppendixContent.propTypes = SearchAppendixItemsProps;
SearchAppendixContent.defaultProps = SearchAppendixItemsDefaultProps;
