import React, { useState, useMemo } from 'react';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AutocompleteSuggestions from 'components/ModernExperience/Search/LocationSearch/AutocompleteSuggestions';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import suggestions from './suggestions.json';

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    position: 'relative',
  },
  searchInput: {
    display: 'block',
    height: '1.1875em',
    minWidth: 0,
    padding: '9.5px 30px 9.5px',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: 20,
    margin: 0,
    boxSizing: 'content-box',
    lineHeight: 2,
    font: 'inherit',
    background: 'none',
    animationName: 'MuiInputBase-keyframes-auto-fill-cancel',
    WebkitTapHighlightColor: 'transparent',
    '&:focus': {
      outline: `2px solid ${theme.palette.focusIndicator}`,
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: 50,
    },
  },
  suggestionList: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 37,
    marginTop: theme.spacing(1),
    zIndex: 1,
    overflow: 'hidden',
    '& .MuiListItem-root.Mui-selected': {
      outline: `2px solid ${theme.palette.focusIndicator}`,
      background: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  listIsOpen: {
    padding: '.5rem',
  },
}));

/**
 * This component is a simple, boilerplate example of an accessible "combobox"
 * Should we standardize out usage of autocompletes throughout the app, this should be the starting point.
 * In the future, we should abstract this component to make recreating accessible comboboxes as simple as plugging in a few props
 */
function DownshiftExample() {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [input, setInput] = useState('');

  const placeholderText = 'Autocomplete example';
  const accessibleTitle = 'Additional context';
  const handleChange = (value) => {
    setInput(value);
  };

  const options = useMemo(() => {
    if (input.length > 0) {
      return suggestions.filter(({ locationInput }) => locationInput?.indexOf(input) !== -1);
    }
    return suggestions;
  }, [input]);

  return (
    <Downshift
      id="location-search"
      defaultHighlightedIndex={0}
      onChange={(selection) => {
        if (selection) {
          setInput(selection.locationInput);
        }
      }}
      inputValue={input}
      itemToString={(item) => item?.title || ''}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        openMenu,
        clearSelection,
      }) => {
        const inputProps = getInputProps({
          placeholder: placeholderText,
          onChange: ({ target: { value } }) => {
            clearSelection();
            handleChange(value);
          },
          onFocus: openMenu,
        });

        // Voiceover on chrome has trouble reading expanded and collapsed state, so we have to add it manually - https://shorturl.at/HKNUW
        const a11yLabel = `${placeholderText}, ${accessibleTitle}, ${
          options.length && isOpen ? 'expanded' : 'collapsed'
        }`;

        return (
          <>
            <div className={classes.autocompleteContainer}>
              <Typography component="label" variant="srOnly" {...getLabelProps()}>
                {a11yLabel}
              </Typography>

              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps({}, { suppressRefError: true })}
                className={classes.inputWrap}
              >
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...inputProps}
                  className={classes.searchInput}
                />
              </div>
              {!smDown && (
                <Paper
                  {...getMenuProps()}
                  rounded="true"
                  classes={{
                    root: `${classes.suggestionList} ${
                      isOpen && options.length && classes.listIsOpen
                    }`,
                  }}
                >
                  {isOpen ? (
                    <AutocompleteSuggestions
                      suggestions={options}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      inputValue={inputValue}
                      showLocationHistory={false}
                    />
                  ) : null}
                </Paper>
              )}
            </div>

            {smDown && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <div {...getMenuProps()} style={{ borderTop: '1px solid gray', overflowY: 'auto' }}>
                <AutocompleteSuggestions
                  suggestions={options}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  inputValue={inputValue}
                  showLocationHistory={false}
                />
              </div>
            )}
          </>
        );
      }}
    </Downshift>
  );
}

export default DownshiftExample;
