/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { logDevMessage } from 'utils/utils';

import * as selectAxios from '../config/selectAxios';
import * as selectConfig from '../config/selectConfig';
import * as selectAiSearch from './selectAiSearch';
import { AI_SEARCH_ENDPOINT } from './aiSearchConstants';
import { formatAiSearchPayload } from './aiSearchUtils';

export const aiSearch = createAsyncThunk('aiSearch', async (args, thunkApi) => {
  const { getState, rejectWithValue } = thunkApi;
  const state = getState();
  const API_URL = selectConfig.apiUrl(state);
  const axios = selectAxios.axiosInstance(state);
  const input = selectAiSearch.searchInputValue(state);
  const payload = formatAiSearchPayload(input);

  if (!payload) {
    logDevMessage(`Malformed payload from message - ${input}`);
    rejectWithValue();
  }

  const url = `${API_URL}/${AI_SEARCH_ENDPOINT}`;

  const res = await axios.post(url, payload);
  return res.data;
});
