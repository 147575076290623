import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { actions, thunks } from 'store/toolkit';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  MIN_SEARCH_STRING,
} from 'store/slices/search/searchConstants';
import { logDevMessage } from 'utils/utils';
import { CARE_CATEGORIES_MAP } from 'utils/constants';

const useFetchAutocompleteSuggestions = () => {
  const dispatch = useDispatch();
  const [pendingPromises, setPendingPromises] = useState([]);

  const debounceFetchSuggestions = useMemo(
    () =>
      debounce(({ autocompleteType }) => {
        pendingPromises?.forEach((prom) => prom.abort());
        const promises = CARE_CATEGORIES_MAP[autocompleteType].map((careCategory) =>
          dispatch(thunks.search.getTypeSuggestions(careCategory))
        );
        setPendingPromises(promises);
      }, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [dispatch, pendingPromises]
  );

  const handleFetchSuggestions = useCallback(
    ({ searchText, autocompleteType }) => {
      if (!CARE_CATEGORIES_MAP[autocompleteType]) {
        logDevMessage(`Invalid search type: ${autocompleteType}`);
        return;
      }
      const isSearchableString = searchText.length >= MIN_SEARCH_STRING;
      // set `isFetching` values synchronously to give the impression the search has begun although the debounce timer has not yet expired
      dispatch(
        actions.search.setAutocompleteTypesFetching({
          careCategories: CARE_CATEGORIES_MAP[autocompleteType],
          isFetching: isSearchableString,
        })
      );
      if (isSearchableString) {
        debounceFetchSuggestions({ autocompleteType });
      } else {
        dispatch(actions.search.clearAutocompleteSuggestions());
      }
    },
    [debounceFetchSuggestions, dispatch]
  );

  return handleFetchSuggestions;
};

export default useFetchAutocompleteSuggestions;
