import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, Collapse } from '@material-ui/core';

import useUpdateTitle from 'hooks/useUpdateTitle';
import { select } from 'store/toolkit';
import { PROVIDERS_APPENDIX_ROUTE } from 'utils/constants';

import SearchForm from 'components/ModernExperience/SearchForm';
import TopSearches from 'components/Pages/LandingPageSections/TopSearches';
import AboutProviderGuide from 'components/Pages/LandingPageSections/AboutProviderGuide';
import LinkToModal from 'components/LinkToModal';

import ExploreBenefits from './LandingPageSections/ExploreBenefits';
import LandingPageSectionWrapper from './LandingPageSections/LandingPageSectionWrapper';
import WelcomeMessage from './WelcomeMessage';
import RewardsSection from './LandingPageSections/RewardsSection';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    marginBottom: 20, // pushes away from the footer
    paddingTop: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  subHeader: {
    marginBottom: 28,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sectionWrapper: {
    marginTop: '50px',
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    maxWidth: 1800,
    borderRadius: '30px',
    rowGap: theme.spacing(9), // 72px
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  searchWrap: {
    width: '73%',
    maxWidth: 978,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  welcomeMessageContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    maxWidth: 1800,
  },
}));

function LandingPage() {
  const classes = useStyles();
  const isSmDown = useSelector(select.ui.isSmDown);
  const isXsDown = useSelector(select.ui.isXsDown);
  const providerLookup = useSelector(select.featureFlags.providerLookup);
  const noPromotionsInRadius = useSelector(select.promotions.noPromotionsInRadius);
  const appName = useSelector(select.content.appName);
  const hasActiveRewardsCampaign = useSelector(select.rewards.hasActiveCampaign);
  const isConfigLoading = useSelector(select.config.isLoading);

  useUpdateTitle('Home');

  return (
    <Grid id="landing-page" classes={{ root: classes.container }} container justifyContent="center">
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Container id="landing-page-search" classes={{ root: classes.searchContainer }}>
          <Typography color="primary" variant="h1" component="strong">
            Find a Quality Provider
          </Typography>
          <Typography classes={{ root: classes.subHeader }}>
            Smarter, more informed decisions about care providers starts here.
          </Typography>
          <div className={classes.searchWrap}>
            <SearchForm compressAiSearchButton={isSmDown} mobileView={isXsDown} />
          </div>
        </Container>
        <Grid
          container
          direction="column"
          id="landing-page-sections"
          classes={{ root: classes.sectionWrapper }}
        >
          <LandingPageSectionWrapper
            title="Top Searches"
            subtitle={
              <>
                Quickly find a quality provider with commonly searched items, or{' '}
                <LinkToModal to={PROVIDERS_APPENDIX_ROUTE}>see all specialties</LinkToModal>
              </>
            }
            id="topsearches"
          >
            <TopSearches />
          </LandingPageSectionWrapper>

          <Collapse mountOnEnter unmountOnExit in={hasActiveRewardsCampaign}>
            <LandingPageSectionWrapper title="Rewards" id="rewards">
              <RewardsSection />
            </LandingPageSectionWrapper>
          </Collapse>

          <LandingPageSectionWrapper
            title="Explore Benefits"
            subtitle={`Take a closer look into the benefits that are accessible through ${appName}`}
            id="benefits"
            hidden={providerLookup || noPromotionsInRadius}
          >
            <ExploreBenefits />
          </LandingPageSectionWrapper>
          <LandingPageSectionWrapper title={`About ${appName}`} id="about" hidden={providerLookup}>
            <AboutProviderGuide />
          </LandingPageSectionWrapper>
        </Grid>
        {providerLookup && (
          <Grid className={classes.welcomeMessageContainer}>
            <WelcomeMessage isLoading={isConfigLoading} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default LandingPage;
