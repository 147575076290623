/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
import { CARE_CATEGORIES } from 'utils/constants';

export function getLabelText({ suggestion, careCategory, useNewSpecialtyAutocomplete }) {
  const {
    entityName,
    specialty,
    name,
    searchTerm,
    specialtyName,
    subspecialtyName,
    serviceName,
    affiliationName,
  } = suggestion || {};
  let labelText = '';
  if (careCategory === CARE_CATEGORIES.PROVIDER_NAME && entityName) {
    labelText = `${entityName}${specialty ? ` (${specialty})` : ''}`;
  } else if (careCategory === CARE_CATEGORIES.FACILITY_NAME) {
    labelText = name;
  } else if (careCategory === CARE_CATEGORIES.PROVIDER_SPECIALTY) {
    labelText = useNewSpecialtyAutocomplete
      ? subspecialtyName || specialtyName
      : searchTerm
      ? `${searchTerm} (${subspecialtyName || specialtyName})`
      : subspecialtyName
      ? `${subspecialtyName}`
      : specialtyName;
  } else if (careCategory === CARE_CATEGORIES.FACILITY_TYPE) {
    labelText = useNewSpecialtyAutocomplete
      ? subspecialtyName || specialtyName
      : searchTerm
      ? `${searchTerm} (${subspecialtyName || specialtyName})`
      : subspecialtyName
      ? `${subspecialtyName}`
      : specialtyName;
  } else if (
    careCategory === CARE_CATEGORIES.PROVIDER_SERVICE ||
    careCategory === CARE_CATEGORIES.FACILITY_SERVICE
  ) {
    labelText = serviceName;
  } else if (careCategory === CARE_CATEGORIES.PROVIDER_AFFILIATION) {
    labelText = affiliationName;
  }
  return labelText;
}
