import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions } from 'store/toolkit';
import { useQueryParams } from 'use-query-params';
import queryParamConfig from 'hooks/queryString/queryParamConfig';

/**
 * Set a user's location based on the network or client default location
 */
export default function useInitializeLocation() {
  const configIsLoading = useSelector(select.config.isLoading);
  const defaultLocation = useSelector(select.networks.defaultLocation);

  const [{ location_input: locationInputParam }] = useQueryParams(queryParamConfig);
  const dispatch = useDispatch();

  const setDefaultLocation = useCallback(
    (location) => {
      if (!locationInputParam) {
        dispatch(
          actions.location.setDefaultLocation({
            locationInput: location.locationInput || `${location.city}, ${location.state}`,
            latLong: { latitude: location.lat, longitude: location.long },
            locationComponents: {
              city: location.city,
              zip: null,
              state: location.state,
            },
            locationResolved: true,
          })
        );
      }
    },
    [dispatch, locationInputParam]
  );

  /**
   * By the time the config is loaded, the user will either:
   * 1) have a valid network from a portal integration
   * 2) have a valid network in localStorage from a previous session
   * 3) have no valid network, and be redirected to /login.
   *
   * In cases (1) and (2), this hook will initialize the network default location, falling back to the client-level default location
   * In case (3) the default location will be set when the user logs in
   */
  useEffect(() => {
    if (!configIsLoading) {
      const locationHistory = JSON.parse(localStorage.getItem('locationHistory'));

      if (locationHistory && locationHistory.length > 0) {
        const lastSearch = locationHistory[0];
        setDefaultLocation({
          locationInput: lastSearch.locationInput,
          city: lastSearch.locationComponents.city,
          state: lastSearch.locationComponents.state,
          zip: lastSearch.locationComponents.zip,
          lat: lastSearch.latLong.latitude,
          long: lastSearch.latLong.longitude,
        });
      } else {
        setDefaultLocation(defaultLocation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading]);
}
