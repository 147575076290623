import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import AiSearch from 'components/Modals/AiSearchModal/AiSearch';
import PageTemplate from './PageTemplate';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 850,
    margin: '0 auto',
    height: ' calc(100vh - 235px)', // header, footer, "go back" bar
    [theme.breakpoints.down('md')]: {
      height: ' calc(100vh - 155px)', // header, "go back" bar
    },
    [theme.breakpoints.down('xs')]: {
      height: ' calc(100vh - 145px)', // header, "go back" bar
    },
  },
}));
function AiSearchPage() {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" classes={classes}>
      <AiSearch />
    </Grid>
  );
}

export default withRouter(PageTemplate(AiSearchPage, 'AI Search'));
