import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { get, debounce } from 'lodash';
import { captureException } from '@sentry/react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GeolocateButton from 'components/ModernExperience/Search/LocationSearch/GeolocateButton';
import AutocompleteSuggestions from 'components/ModernExperience/Search/LocationSearch/AutocompleteSuggestions';
import useFocusAnchors, { useFocusTo } from 'utils/FocusRefContext';
import { actions, select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: 0,
  },
  containerMobile: {
    margin: '0 1rem',
  },
  suggestionList: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 45,
    marginTop: theme.spacing(1),
    zIndex: 1050,
    overflow: 'hidden',
    '& .MuiListItem-root.Mui-selected': {
      outline: `2px solid ${theme.palette.focusIndicator}`,
      background: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  listIsOpen: {
    padding: '.5rem',
  },
  // these styles mimic mui styling
  inputWrap: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    margin: '8px 0 8px',
    fontSize: '1rem',
    lineHeight: '1.1875em',
    cursor: 'text',
    color: theme.palette.text.primary,
  },
  searchInput: {
    display: 'block',
    height: '1.1875em',
    minWidth: 0,
    width: '100%',
    padding: '9.5px 9.5px 9.5px 46px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '50px 0 0 50px',
    margin: 0,
    boxSizing: 'content-box',
    lineHeight: 2,
    font: 'inherit',
    background: 'none',
    animationName: 'MuiInputBase-keyframes-auto-fill-cancel',
    WebkitTapHighlightColor: 'transparent',
    '&:focus': {
      zIndex: 1,
      outline: `2px solid ${theme.palette.focusIndicator}`,
    },
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.grey[600],
      fontSize: '.875rem',
    },
  },
  searchInputWithX: {
    paddingRight: 40,
  },
  searchInputMobile: {
    borderRadius: 20,
    paddingRight: 46,
  },
  floatingAdornment: {
    position: 'absolute',
    display: 'block',
    zIndex: 1,
    top: 10,
    bottom: 10,
    '& button': {
      padding: 6,
    },
  },
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    clearInputButton: {
      '& svg': {
        fill: 'white',
      },
    },
  },
}));

function LocationAutocomplete({ autoFocus, autoSearch, submitSearch, mobileView }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const locationInput = useSelector(select.location.locationInput);
  const locationResolved = useSelector(select.location.locationResolved);
  const latLong = useSelector(select.location.latLong);

  const [showLocationHistory, setShowLocationHistory] = useState(!locationInput);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const searchInput = useSelector(select.search.text);
  const isBoundingBoxSearch = useSelector(select.search.isBoundingBoxSearch);
  const locationHistory = useSelector(select.location.locationHistory);
  const azureMapsKey = useSelector(select.tokens.azureMaps);
  const defaultSearchRadius = useSelector(select.config.defaultSearchRadius);

  const focusAnchors = useFocusAnchors();
  const focusTo = useFocusTo();

  useEffect(() => {
    if (locationInput) {
      setShowLocationHistory(false);
    }
  }, [locationInput]);

  const submitAutoSearchOrDont = ({ latitude, longitude }) => {
    if (isBoundingBoxSearch) {
      dispatch(actions.filters.setFilterRadius(defaultSearchRadius));
    }

    if (autoSearch && submitSearch && searchInput) {
      submitSearch();
    } else {
      dispatch(actions.map.moveCenterTo({ latitude, longitude }));
    }
  };

  const handleClearLocation = (openMenu) => {
    dispatch(actions.location.handleClearLocation());
    setShowLocationHistory(true);
    setLocationSuggestions([]); // this is a local state value and does not need to go into redux
    focusTo.locationInput();
    openMenu();
  };

  const fetchLocationSuggestions = useMemo(
    () =>
      debounce((value) => {
        setIsLoadingSuggestions(true);
        const url = `https://atlas.microsoft.com/search/fuzzy/json?subscription-key=${azureMapsKey}&api-version=1.0&typeahead=true&countrySet=US,PR,VI&lat=${latLong.latitude}&lon=${latLong.longitude}&query=${value}`;
        fetch(url)
          .then((r) => r.json())
          .then((response) => {
            const { summary, results: suggestions } = response;
            if (summary?.numResults > 0) {
              const labels = {};
              const dedupedResults = suggestions.reduce((acc, suggestion) => {
                const label = suggestion.poi
                  ? `${suggestion.poi.name}, ${suggestion.address.freeformAddress}`
                  : suggestion.address?.freeformAddress;
                if (!labels[label]) {
                  acc.push({ ...suggestion, label });
                  labels[label] = true;
                }

                return acc;
              }, []);
              setLocationSuggestions(dedupedResults);
            } else {
              setLocationSuggestions([]);
            }
          })
          .catch((err) => {
            setLocationSuggestions([]);
            captureException(err);
          })
          .finally(() => setIsLoadingSuggestions(false));
      }, 300),
    [azureMapsKey, latLong.latitude, latLong.longitude]
  );

  const handleChange = useCallback(
    (value) => {
      setShowLocationHistory(!value);

      dispatch(actions.location.handleLocationAutoCompleteChange(value));

      if (value && value.length > 1) {
        fetchLocationSuggestions(value);
      } else {
        setLocationSuggestions([]);
      }
    },
    [dispatch, fetchLocationSuggestions]
  );

  const handleSelected = (suggestion) => {
    const {
      address,
      position: { lat, lon },
      entityType,
    } = suggestion;
    if (lat && lon) {
      const suggestionCoords = { latitude: lat, longitude: lon };
      const state = address.countrySubdivision;
      const city = address.municipality;
      const locationComponents = { zip: address.postalCode || null, city, state };
      const eventAction = suggestion.poi ? suggestion.poi.categories[0] : entityType;

      dispatch(actions.ui.closeModal('location'));
      dispatch(
        actions.location.updateLocationFromAutocompleteSuggestion({
          locationInput: suggestion.label,
          latLong: suggestionCoords,
          locationComponents,
          locationResolved: true,
        })
      );
      dispatch(_analyticsActions.locationAutocompleteSelected(eventAction));
      submitAutoSearchOrDont(suggestionCoords);
    } else {
      // @TODO: handle no geo found error
      console.error('No geo data found for selected location!');
    }

    setLocationSuggestions([]);
  };

  const handleSelectFirst = () => {
    if (locationSuggestions[0]) {
      handleSelected(locationSuggestions[0]);
    }
  };

  // eslint-disable-next-line no-shadow
  const handleSelectFromHistory = ({ locationInput, latLong, locationComponents }) => {
    setShowLocationHistory(false);
    dispatch(actions.ui.closeModal('location'));

    dispatch(
      actions.location.updateLocationFromHistory({
        locationInput,
        latLong,
        locationComponents,
        locationResolved: true,
      })
    );

    submitAutoSearchOrDont(latLong);
  };

  const suggestions = showLocationHistory ? locationHistory : locationSuggestions;
  const placeholderText = 'Address, city, or zip code';

  return (
    <Downshift
      id="location-search"
      defaultHighlightedIndex={0}
      onChange={(selection) => {
        if (selection) {
          // eslint-disable-next-line no-unused-expressions
          showLocationHistory ? handleSelectFromHistory(selection) : handleSelected(selection);
        }
      }}
      inputValue={locationInput}
      itemToString={(item) => get(item, 'description', '')}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        openMenu,
        clearSelection,
      }) => {
        const inputProps = getInputProps({
          placeholder: placeholderText,
          onChange: ({ target: { value } }) => {
            clearSelection();
            handleChange(value);
          },
          onFocus: showLocationHistory ? openMenu : undefined,
          onBlur: showLocationHistory ? undefined : handleSelectFirst,
        });

        // Voiceover on chrome has trouble reading expanded and collapsed state, so we have to add it manually - https://shorturl.at/HKNUW
        const a11yLabel = `${placeholderText}, Location Search Input, ${
          (suggestions.length && isOpen) || isLoadingSuggestions ? 'expanded' : 'collapsed'
        }`;
        return (
          <>
            <div
              className={clsx(classes.container, { [classes.containerMobile]: mobileView })}
              data-testid="location-autocomplete-wrapper"
            >
              <Typography component="label" variant="srOnly" {...getLabelProps()}>
                {a11yLabel}
              </Typography>

              <div className={classes.floatingAdornment} style={{ left: 5 }}>
                <GeolocateButton />
              </div>

              <div
                aria-controls="location-search-menu"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps({}, { suppressRefError: true })}
                className={classes.inputWrap}
                aria-expanded={isOpen && suggestions.length > 0}
              >
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...inputProps}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={autoFocus}
                  ref={focusAnchors.locationInput}
                  className={clsx(classes.searchInput, {
                    [classes.searchInputMobile]: mobileView,
                    [classes.searchInputWithX]: Boolean(locationResolved),
                  })}
                />
              </div>

              {Boolean(locationResolved) && (
                <div
                  className={`${classes.floatingAdornment} ${classes.clearInputButton}`}
                  style={{ right: 6 }}
                >
                  <IconButton
                    onClick={() => {
                      clearSelection();
                      handleClearLocation(openMenu);
                    }}
                  >
                    <Typography variant="srOnly">Clear location input</Typography>
                    <CloseIcon alt="" />
                  </IconButton>
                </div>
              )}

              {!mobileView && (
                <Paper
                  {...getMenuProps()}
                  rounded="true"
                  classes={{
                    root: `${classes.suggestionList} ${
                      isOpen && suggestions.length && classes.listIsOpen
                    }`,
                  }}
                >
                  {isOpen ? (
                    <AutocompleteSuggestions
                      suggestions={suggestions}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      inputValue={inputValue}
                      showLocationHistory={showLocationHistory}
                    />
                  ) : null}
                </Paper>
              )}
            </div>

            {mobileView && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <div {...getMenuProps()} style={{ borderTop: '1px solid gray', overflowY: 'auto' }}>
                <AutocompleteSuggestions
                  suggestions={suggestions}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  inputValue={inputValue}
                  showLocationHistory={showLocationHistory}
                />
              </div>
            )}
          </>
        );
      }}
    </Downshift>
  );
}

LocationAutocomplete.propTypes = {
  autoFocus: PropTypes.bool,
  autoSearch: PropTypes.bool,
  submitSearch: PropTypes.func,
  mobileView: PropTypes.bool.isRequired,
};

LocationAutocomplete.defaultProps = {
  autoFocus: false,
  autoSearch: false,
  submitSearch: undefined,
};

export default LocationAutocomplete;
